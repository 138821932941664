main a:not(.c-button__primary):not(.wp-block-button__link) {
  position: relative;

  &::after {
    position: absolute;
    bottom: -0.25em;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background-color: currentColor;
    transition: 0.5s cubic-bezier(0.5, 0.06, 0.3, 0.9);
    transition-property: transform;
    transform: scaleX(0);
    transform-origin: right;
  }

  &:hover {
    color: rgba($color_text, 0.6);

    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}
