$color_text: #505050;
$color_bg: #fff;

$color_white: #fff;
$color_red: #a50707;
$color_primary: #163160;

$color_link: $color_text;
$color_link--hover: #807e7d;
// $color_link--hover: rgba($color_text, .6);
$color_link--visited: $color_text;

$color_border: #505050;
