/*! purgecss start ignore */
.wp-pagenavi {
  padding-top: 3rem;
  margin-top: 5rem;
  border-top: 1px solid $color_border;

  .current {
    color: #fff;
    background-color: $color_border;
  }

  .page {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: inline-block;
      width: 0;
      height: 100%;
      content: '';
      background-color: $color_primary;
      transition: $duration;
    }

    &:hover:before {
      width: 100%;
    }

    &:after {
      content: unset !important;
    }
  }

  .previouspostslink,
  .nextpostslink,
  .current,
  .page {
    position: relative;
    z-index: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 2px;
    border: 1px solid $color_border;

    &:hover {
      color: #fff;
    }
  }

  .previouspostslink,
  .nextpostslink {
    border: none;

    span {
      text-indent: -9999px;
    }

    &:hover:before {
      border-top: 3px solid $color_primary;
      border-right: 3px solid $color_primary;
    }

    &:before {
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      display: inline-block;
      width: 8px;
      height: 8px;
      content: '';
      border-top: 3px solid $color_border;
      border-right: 3px solid $color_border;
      transition: $duration;
      transform: rotate(45deg);
    }

    &:after {
      content: unset !important;
    }
  }

  .previouspostslink {
    &:before {
      transform: rotate(-135deg);
    }
  }
}
/*! purgecss end ignore */
