@charset "UTF-8";
/*
 * foundation
 */
@import 'https://use.fontawesome.com/releases/v5.0.13/css/all.css';
@keyframes line-scroll {
  0% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  50% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  51% {
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}
@keyframes line-link {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }
  50% {
    transform: scaleX(0);
    transform-origin: right;
  }
  51% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}
/* $duration: all .3s ease-out !default; */
html {
  font-size: 16px;
  font-size: 1rem;
}

/*! purgecss start ignore */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box; /* 1 */
  cursor: default; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main { /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  line-height: 1.7;
}

body {
  /* メトリクスカーニングを指定する */
  height: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, $font-family-base; */
  font-family: "游ゴシック Medium", "Yu Gothic Medium", 游ゴシック体, "Yu Gothic", YuGothic, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, メイリオ, "MS PGothic", Osaka, arial, sans-serif;
  font-feature-settings: "palt";
  font-kerning: normal;
  color: #505050;
  letter-spacing: 0.1em;
  background-color: #fff;
  /* アンチエイリアスを指定する */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.35;
  color: inherit;
}

ul,
ol {
  padding: 0;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

able {
  width: 100%;
}

th {
  text-align: left;
}

a {
  color: #505050;
  text-decoration: none;
  outline: none;
  transition: 0.2s;
}
a:visited {
  color: #505050;
}
a:hover {
  color: #807e7d;
}

/**
 * フルードイメージにします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

button {
  outline: none;
}

/**
 * IEでSVGがフルードイメージにならない不具合があります。
 * 属性値が`.svg`で終わる要素に適応します。
 */
[src$=".svg"],
[data$=".svg"] {
  width: 100%;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin: 0;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

.leaflet-bar button,
.leaflet-bar button:hover {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar button {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
}

.leaflet-bar button:hover {
  background-color: #f4f4f4;
}

.leaflet-bar button:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar button:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar.disabled,
.leaflet-bar button.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.easy-button-button .button-state {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.leaflet-touch .leaflet-bar button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.s-news__content h2,
.s-solution-detail .l-article__body h2 {
  font-size: 28px;
  font-size: 1.75rem;
  padding: 0.25rem 0 0 0.5rem;
  margin-top: 5rem;
  color: #163160;
  border-left: 7px solid #163160;
}
.s-news__content h2:first-of-type,
.s-solution-detail .l-article__body h2:first-of-type {
  margin-top: 0;
}
.s-news__content h3,
.s-solution-detail .l-article__body h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  padding: 0.25rem 0.75rem;
  margin-top: 5rem;
  background-color: #f0f0f0;
  border: 2px solid #e3e3e3;
}
.s-news__content h4,
.s-solution-detail .l-article__body h4 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 4rem;
  color: #163160;
}
.s-news__content h3 + h4,
.s-solution-detail .l-article__body h3 + h4 {
  margin-top: 1.5rem;
}
.s-news__content h2 + .wp-block-image,
.s-news__content h3 + .wp-block-image,
.s-news__content h4 + .wp-block-image,
.s-news__content h2 + .wp-block-media-text,
.s-news__content h3 + .wp-block-media-text,
.s-news__content h4 + .wp-block-media-text,
.s-solution-detail .l-article__body h2 + .wp-block-image,
.s-solution-detail .l-article__body h3 + .wp-block-image,
.s-solution-detail .l-article__body h4 + .wp-block-image,
.s-solution-detail .l-article__body h2 + .wp-block-media-text,
.s-solution-detail .l-article__body h3 + .wp-block-media-text,
.s-solution-detail .l-article__body h4 + .wp-block-media-text {
  margin: 1.5rem 0 0;
}
.s-news__content ul,
.s-solution-detail .l-article__body ul {
  padding: 0 0.5rem;
  margin-top: 2rem;
  border-left: 7px solid #e3e3e3;
}
.s-news__content ul li,
.s-solution-detail .l-article__body ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 1rem;
  list-style: none;
}
.s-news__content ul li:before,
.s-solution-detail .l-article__body ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #e3e3e3;
  content: "●";
}
.s-news__content p,
.s-solution-detail .l-article__body p {
  margin-top: 1rem;
}

.wp-block-columns {
  margin: 2.5rem 0 0;
}

.wp-block-image {
  margin: 2.5rem 0 0;
}
.wp-block-image:first-child {
  margin: 0;
}
.wp-block-image figcaption {
  font-size: 15px;
  font-size: 0.9375rem;
  margin: 0.5em 0 0;
}
.wp-block-image figcaption strong {
  font-size: 18px;
  font-size: 1.125rem;
  color: #163160;
}

.wp-block-video,
.wp-block-media-text {
  margin: 2.5rem 0 0;
}

.wp-block-table {
  margin: 1.5rem 0 0;
}
.wp-block-table table {
  border-spacing: 0;
  border-collapse: collapse;
  /* border: 1px solid #c8c8c8; */
}
@media screen and (max-width: 768px) {
  .wp-block-table table {
    border-top: 1px solid #c8c8c8;
  }
}
.wp-block-table table td {
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #c8c8c8;
}
@media screen and (max-width: 768px) {
  .wp-block-table table td {
    display: block;
    border-top: none;
  }
}
.wp-block-table table tr td:nth-of-type(1) {
  background-color: #ebebeb;
  border-right: none;
}
@media screen and (max-width: 768px) {
  .wp-block-table table tr td:nth-of-type(1) {
    border: 1px solid #c8c8c8;
    border-top: none;
  }
}
.wp-block-table table tr td:nth-of-type(2) {
  border-left: none;
}
@media screen and (max-width: 768px) {
  .wp-block-table table tr td:nth-of-type(2) {
    border: 1px solid #c8c8c8;
    border-top: none;
  }
}

.wp-block-button__link {
  background-color: #aaa;
}

/*! purgecss end ignore */
.leaflet-bar button,
.leaflet-bar button:hover {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar button {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
}

.leaflet-bar button:hover {
  background-color: #f4f4f4;
}

.leaflet-bar button:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar button:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar.disabled,
.leaflet-bar button.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.easy-button-button .button-state {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.leaflet-touch .leaflet-bar button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/*! purgecss start ignore */
.wp-pagenavi {
  padding-top: 3rem;
  margin-top: 5rem;
  border-top: 1px solid #505050;
}
.wp-pagenavi .current {
  color: #fff;
  background-color: #505050;
}
.wp-pagenavi .page:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: inline-block;
  width: 0;
  height: 100%;
  content: "";
  background-color: #163160;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.wp-pagenavi .page:hover:before {
  width: 100%;
}
.wp-pagenavi .page:after {
  content: unset !important;
}
.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink,
.wp-pagenavi .current,
.wp-pagenavi .page {
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  border: 1px solid #505050;
}
.wp-pagenavi .previouspostslink:hover,
.wp-pagenavi .nextpostslink:hover,
.wp-pagenavi .current:hover,
.wp-pagenavi .page:hover {
  color: #fff;
}
.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink {
  border: none;
}
.wp-pagenavi .previouspostslink span,
.wp-pagenavi .nextpostslink span {
  text-indent: -9999px;
}
.wp-pagenavi .previouspostslink:hover:before,
.wp-pagenavi .nextpostslink:hover:before {
  border-top: 3px solid #163160;
  border-right: 3px solid #163160;
}
.wp-pagenavi .previouspostslink:before,
.wp-pagenavi .nextpostslink:before {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  display: inline-block;
  width: 8px;
  height: 8px;
  content: "";
  border-top: 3px solid #505050;
  border-right: 3px solid #505050;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
  transform: rotate(45deg);
}
.wp-pagenavi .previouspostslink:after,
.wp-pagenavi .nextpostslink:after {
  content: unset !important;
}
.wp-pagenavi .previouspostslink:before {
  transform: rotate(-135deg);
}

/*! purgecss end ignore */
/*
 * layout
 */
/*! purgecss start ignore */
.l-article {
  background-color: #fff;
}
.l-article__body {
  width: 94%;
  /* max-width: 1440px; */
  max-width: 1440px;
  margin: auto;
  /* .s-solution & { */
  /*   display: flex; */
  /*   flex-wrap: wrap; */
  /*   justify-content: space-between; */
  /*   padding-top: 2rem; */
  /* } */
}
.s-news .l-article__body {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .s-news .l-article__body {
    flex-direction: column;
  }
}

/*! purgecss end ignore */
.l-content {
  position: relative;
  z-index: 100;
  padding: 70px 0 0;
}
@media screen and (min-width: 960px) {
  .l-content {
    padding: 0 0 0 220px;
    overflow: hidden;
  }
}

.l-footer {
  padding: 5rem 0 1rem;
  background-color: #fff;
}

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 70px;
  background-color: #fff;
}
@media screen and (min-width: 960px) {
  .l-header {
    width: 220px;
    height: 100%;
    border-right: 1px solid #505050;
    border-bottom: none;
  }
}

/*! purgecss start ignore */
.s-home .l-main {
  background-color: #fff;
}

/*! purgecss end ignore */
/*
 * object.
 */
/*! purgecss start ignore */
.c-button__primary, .s-home__solutions-title, .wp-block-button__link {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 250px;
  padding: 1rem 0.5rem;
  margin: auto;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.c-button__primary:hover, .s-home__solutions-title:hover, .wp-block-button__link:hover, .c-button__primary:visited, .s-home__solutions-title:visited, .wp-block-button__link:visited {
  color: #fff;
}
.c-button__primary:hover:before, .s-home__solutions-title:hover:before, .wp-block-button__link:hover:before {
  width: 100%;
}
.c-button__primary:before, .s-home__solutions-title:before, .wp-block-button__link:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: inline-block;
  width: 0;
  height: 100%;
  content: "";
  background-color: #163160;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.c-button__primary:after, .s-home__solutions-title:after, .wp-block-button__link:after {
  position: absolute;
  top: calc(50% - 5px);
  right: 13px;
  display: inline-block;
  width: 8px;
  height: 8px;
  content: "";
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}

.c-button__primary, .s-home__solutions-title {
  background-color: #aaa;
  /* &:hover { */
  /*   background-color: rgba(#aaa, 0.7); */
  /* } */
}
@media screen and (max-width: 768px) {
  .c-button__primary + .c-button__primary, .s-home__solutions-title + .c-button__primary, .c-button__primary + .s-home__solutions-title, .s-home__solutions-title + .s-home__solutions-title {
    margin-top: 1rem;
  }
}

/*! purgecss end ignore */
main a:not(.c-button__primary):not(.s-home__solutions-title):not(.wp-block-button__link) {
  position: relative;
}
main a:not(.c-button__primary):not(.s-home__solutions-title):not(.wp-block-button__link)::after {
  position: absolute;
  bottom: -0.25em;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  background-color: currentColor;
  transition: 0.5s cubic-bezier(0.5, 0.06, 0.3, 0.9);
  transition-property: transform;
  transform: scaleX(0);
  transform-origin: right;
}
main a:not(.c-button__primary):not(.s-home__solutions-title):not(.wp-block-button__link):hover {
  color: rgba(80, 80, 80, 0.6);
}
main a:not(.c-button__primary):not(.s-home__solutions-title):not(.wp-block-button__link):hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.l-main .c-table-hours {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #505050;
}
.l-main .c-table-hours tr:nth-of-type(2) {
  background-color: #f2f2f2;
}
.l-main .c-table-hours th {
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background: #7b7b7b;
  border-left: 1px solid #505050;
}
.l-main .c-table-hours td {
  padding: 0.5rem 0;
  line-height: 1.3;
  text-align: center;
  border-top: 1px solid #505050;
  border-left: 1px solid #505050;
}
.l-main .c-table-hours td div {
  font-size: 12px;
  font-size: 0.75rem;
}
.l-main .c-table-hours .surgrey {
  font-size: 13px;
  font-size: 0.8125rem;
}
.l-main .c-table-hours .surgrey div {
  display: block;
}
.l-main .c-table-hours__head {
  width: 256px;
}
.l-main .c-table-hours__caption {
  margin-top: 0.5rem;
}
@media screen and (min-width: 769px) {
  .l-main .c-table-hours__caption {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.l-main .c-table-hours__open {
  font-size: 12px;
  font-size: 0.75rem;
}

.c-table-recruit {
  margin-top: 50px;
}
.c-table-recruit caption {
  font-size: 30px;
  font-size: 1.875rem;
  font-family: "Noto Serif JP", "游明朝", "ヒラギノ明朝 ProN W3", YuMincho, "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 100;
}
.c-table-recruit th {
  width: 20%;
  padding: 0.75rem;
  text-align: right;
  border-top: 1px solid #505050;
}
.c-table-recruit td {
  width: 80%;
  padding: 0.75rem;
  border-top: 1px solid #505050;
}

/*! purgecss start ignore */
.p-cover {
  position: fixed;
  width: 100%;
  height: 400px;
  padding-left: 220px;
  background-size: cover;
}
@media screen and (max-width: 959px) {
  .p-cover {
    height: 300px;
    padding: 0;
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .p-cover {
    height: 200px;
  }
}
@media screen and (max-width: 455px) {
  .p-cover {
    height: 150px;
  }
}
.s-about-us .p-cover {
  background-image: url("../images/cover-about-us.jpg");
}
.s-news .p-cover {
  background-image: url("../images/cover-news.jpg");
}
.s-solution .p-cover, .s-solution-detail .p-cover {
  background-image: url("../images/cover-solution.jpg");
}
.s-solution-detail .p-cover {
  height: 200px;
}
@media screen and (max-width: 455px) {
  .s-solution-detail .p-cover {
    height: 150px;
  }
}
.s-privacy .p-cover, .s-contact .p-cover {
  background-color: #163160;
}

/*! purgecss end ignore */
.p-footer {
  display: flex;
  justify-content: space-between;
  width: 94%;
  max-width: 1440px;
  margin: auto;
}
@media screen and (max-width: 455px) {
  .p-footer {
    flex-direction: column-reverse;
  }
}
.p-footer__copyright {
  font-size: 12px;
  font-size: 0.75rem;
}
.p-footer__link {
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (max-width: 455px) {
  .p-footer__link {
    margin-bottom: 1rem;
  }
}
.p-footer__link:before {
  display: inline-block;
  content: "・";
}

/*! purgecss start ignore */
/*! purgecss end ignore */
.p-header__title {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 959px) {
  .p-header__title {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 70px;
    line-height: 1;
    background-color: #fff;
    border-bottom: 1px solid #505050;
  }
}
@media screen and (min-width: 960px) {
  .p-header__title {
    height: 199px;
  }
}
.p-header__title img {
  height: 53px;
}
@media screen and (min-width: 960px) {
  .p-header__title img {
    height: 53px;
  }
}
.p-header__button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  width: 50px;
  height: 50px;
  border: none;
}
.p-header__button span {
  position: absolute;
  top: 12px;
  display: block;
  width: 40px;
  height: 3px;
  background-color: #333;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.is-opened .p-header__button span {
  background-color: transparent;
  /* transition-delay: 0.22s; */
}
.p-header__button span:before, .p-header__button span:after {
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  content: "";
  background-color: #333;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.p-header__button span:before {
  top: 10px;
}
.is-opened .p-header__button span:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.p-header__button span:after {
  top: 20px;
}
.is-opened .p-header__button span:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
@media screen and (min-width: 960px) {
  .p-header__button {
    display: none;
  }
}
.p-header__menu {
  position: absolute;
  top: -300px;
  z-index: 0;
  width: 100%;
  background-color: #fff;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.is-opened .p-header__menu {
  top: 70px;
}
@media screen and (min-width: 960px) {
  .p-header__menu {
    position: unset;
  }
}
.p-header__menu-item {
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #505050;
}
@media screen and (min-width: 960px) {
  .p-header__menu-item:first-of-type {
    border-top: 1px solid #505050;
  }
}
.p-header__menu-item:before {
  position: absolute;
  z-index: -1;
  display: inline-block;
  width: 12px;
  height: 100%;
  content: "";
  background-color: #163160;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.p-header__menu-item:hover:before {
  width: 100%;
}
.p-header__menu-item a {
  display: block;
  padding: 1.25rem 0;
  padding-left: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  /* &:before { */
  /*   @include font-size(20); */
  /*   display: block; */
  /*   margin-bottom: 5px; */
  /*   font-family: 'Poppins', sans-serif; */
  /*   font-weight: 400; */
  /*   letter-spacing: 5px; */
  /*   content: attr(data-text); */
  /* } */
}
.p-header__menu-item a:hover {
  color: #fff;
}

/*! purgecss start ignore */
.s-home .p-list-information {
  font-size: 15px;
  font-size: 0.9375rem;
  display: inline-flex;
  flex-wrap: wrap;
  width: 80%;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .s-home .p-list-information {
    width: 100%;
    margin-top: 1rem;
  }
}
.s-home .p-list-information dt {
  width: 7rem;
  margin-top: 0.5rem;
}
.s-home .p-list-information dt:first-of-type {
  margin-top: 0;
}
.s-home .p-list-information dd {
  width: calc(100% - 7rem);
  margin-top: 0.5rem;
}
.s-home .p-list-information dd:first-of-type {
  margin-top: 0;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.p-title {
  font-size: 46px;
  font-size: 2.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* &:before { */
  /*   @include font-size(40); */
  /*   display: block; */
  /*   margin-bottom: 8px; */
  /*   font-family: 'Poppins', sans-serif; */
  /*   font-weight: 700; */
  /*   letter-spacing: 5px; */
  /*   content: attr(data-text); */
  /**/
  /*   @include mq-down(md) { */
  /*     @include font-size(30); */
  /*   } */
  /* } */
}
@media screen and (max-width: 959px) {
  .p-title {
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .p-title {
    font-size: 30px;
    font-size: 1.875rem;
    height: 200px;
  }
}
@media screen and (max-width: 455px) {
  .p-title {
    height: 150px;
  }
}
.s-solution-detail .p-title {
  height: 200px;
  font-size: 24px;
  font-size: 1.5rem;
}
.s-solution-detail .p-title:before {
  content: unset;
}
@media screen and (max-width: 455px) {
  .s-solution-detail .p-title {
    height: 150px;
  }
}

/*! purgecss end ignore */
/*! purgecss start ignore */
body.s-about-us {
  --wp--style--block-gap: 6rem;
}

.s-about-us__map {
  height: 400px;
  margin: 2.5rem 0 0;
}

.s-about-us .l-article__body h2 {
  font-size: 21px;
  font-size: 1.3125rem;
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: auto;
  margin-top: 5rem;
  color: #163160;
}
.s-about-us .l-article__body h2:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #163160;
}
.s-about-us .l-article__body .wp-block-group + p {
  margin: 1.5rem 0 0;
}
.s-about-us .l-article__body .wp-block-column h3 {
  font-size: 28px;
  font-size: 1.75rem;
  position: relative;
  padding: 0.25rem 0 0.25rem 3rem;
  margin: 2.5rem 0 0 -3rem;
  color: #fff;
  background-color: #737373;
}
@media screen and (max-width: 768px) {
  .s-about-us .l-article__body .wp-block-column h3 {
    padding: 0.25rem 1rem 0.25rem;
    margin: 2.5rem 0 0;
  }
}
.s-about-us .l-article__body .wp-block-column p {
  margin: 1rem 0 0;
}
.s-about-us .l-article__body .wp-block-column strong {
  font-size: 18px;
  font-size: 1.125rem;
}
.s-about-us .l-article__body .wp-block-table {
  margin: 2.5rem 0 0;
}
.s-about-us .l-article__body .wp-block-table td {
  padding: 0.25rem 0.75rem;
}
@media screen and (min-width: 769px) {
  .s-about-us .l-article__body .wp-block-table td {
    padding: 1.5rem;
  }
}
.s-about-us .l-article__body dl {
  margin: 2.5rem 0 0;
}
.s-about-us .l-article__body dl dt {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}
.s-about-us .l-article__body dl dd {
  font-size: 14px;
  font-size: 0.875rem;
}
@media screen and (min-width: 769px) {
  .s-about-us .l-article__body .wp-block-columns + dl {
    margin: 1.5rem 0 0;
  }
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.s-privacy .l-article__body,
.s-contact .l-article__body {
  padding-top: 1rem;
}
.s-privacy .l-article__body h2,
.s-contact .l-article__body h2 {
  font-size: 21px;
  font-size: 1.3125rem;
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: auto;
  margin-top: 5rem;
  color: #163160;
}
.s-privacy .l-article__body h2:after,
.s-contact .l-article__body h2:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #163160;
}
.s-privacy .l-article__body p,
.s-contact .l-article__body p {
  margin-top: 1.5rem;
  text-align: center;
}
@media screen and (max-width: 959px) {
  .s-privacy .l-article__body p,
  .s-contact .l-article__body p {
    text-align: left;
  }
}
.s-privacy .l-article__body strong,
.s-contact .l-article__body strong {
  color: #163160;
}
.s-privacy .l-article__body .wp-block-group + .wp-block-group p,
.s-contact .l-article__body .wp-block-group + .wp-block-group p {
  margin: 1rem auto 0;
  text-align: center;
}
.s-privacy .l-article__body .wp-block-group + .wp-block-group strong,
.s-contact .l-article__body .wp-block-group + .wp-block-group strong {
  font-size: 24px;
  font-size: 1.5rem;
}
@media screen and (max-width: 455px) {
  .s-privacy .l-article__body .wp-block-group + .wp-block-group strong,
  .s-contact .l-article__body .wp-block-group + .wp-block-group strong {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.s-privacy .l-article__body .wp-block-group + .wp-block-group strong a,
.s-contact .l-article__body .wp-block-group + .wp-block-group strong a {
  color: #163160;
}
.s-privacy .l-article__body .wpcf7,
.s-contact .l-article__body .wpcf7 {
  max-width: 920px;
  margin: 2rem auto 0;
}
.s-privacy .l-article__body .wpcf7 p,
.s-contact .l-article__body .wpcf7 p {
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 1.5rem;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 959px) {
  .s-privacy .l-article__body .wpcf7 p,
  .s-contact .l-article__body .wpcf7 p {
    text-align: left;
  }
}
.s-privacy .l-article__body .wpcf7 form,
.s-contact .l-article__body .wpcf7 form {
  width: 100%;
  border-top: 1px solid #ddd;
}
.s-privacy .l-article__body .wpcf7 .form-row,
.s-contact .l-article__body .wpcf7 .form-row {
  display: flex;
  border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 768px) {
  .s-privacy .l-article__body .wpcf7 .form-row,
  .s-contact .l-article__body .wpcf7 .form-row {
    flex-direction: column;
  }
}
.s-privacy .l-article__body .wpcf7 .form-label,
.s-contact .l-article__body .wpcf7 .form-label {
  display: flex;
  align-items: center;
  width: 30%;
  padding: 1.5rem 1rem;
  background-color: #f5f5f5;
}
@media screen and (max-width: 768px) {
  .s-privacy .l-article__body .wpcf7 .form-label,
  .s-contact .l-article__body .wpcf7 .form-label {
    width: 100%;
    padding: 1rem;
  }
}
.s-privacy .l-article__body .wpcf7 .form-label label,
.s-contact .l-article__body .wpcf7 .form-label label {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0;
  font-weight: normal;
}
.s-privacy .l-article__body .wpcf7 .form-label .required,
.s-contact .l-article__body .wpcf7 .form-label .required {
  font-size: 12px;
  font-size: 0.75rem;
  display: inline-block;
  padding: 2px 5px;
  margin-left: 0.5rem;
  color: #fff;
  background-color: #e74c3c;
  border-radius: 2px;
}
.s-privacy .l-article__body .wpcf7 .form-input,
.s-contact .l-article__body .wpcf7 .form-input {
  width: 70%;
  padding: 1.5rem 1rem;
}
@media screen and (max-width: 768px) {
  .s-privacy .l-article__body .wpcf7 .form-input,
  .s-contact .l-article__body .wpcf7 .form-input {
    width: 100%;
    padding: 1rem;
  }
}
.s-privacy .l-article__body .wpcf7 .form-input input[type=text],
.s-privacy .l-article__body .wpcf7 .form-input input[type=email],
.s-privacy .l-article__body .wpcf7 .form-input input[type=tel],
.s-privacy .l-article__body .wpcf7 .form-input select,
.s-privacy .l-article__body .wpcf7 .form-input textarea,
.s-contact .l-article__body .wpcf7 .form-input input[type=text],
.s-contact .l-article__body .wpcf7 .form-input input[type=email],
.s-contact .l-article__body .wpcf7 .form-input input[type=tel],
.s-contact .l-article__body .wpcf7 .form-input select,
.s-contact .l-article__body .wpcf7 .form-input textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
  font-size: 0.875rem;
}
.s-privacy .l-article__body .wpcf7 .form-input input[type=text]::placeholder,
.s-privacy .l-article__body .wpcf7 .form-input input[type=email]::placeholder,
.s-privacy .l-article__body .wpcf7 .form-input input[type=tel]::placeholder,
.s-privacy .l-article__body .wpcf7 .form-input select::placeholder,
.s-privacy .l-article__body .wpcf7 .form-input textarea::placeholder,
.s-contact .l-article__body .wpcf7 .form-input input[type=text]::placeholder,
.s-contact .l-article__body .wpcf7 .form-input input[type=email]::placeholder,
.s-contact .l-article__body .wpcf7 .form-input input[type=tel]::placeholder,
.s-contact .l-article__body .wpcf7 .form-input select::placeholder,
.s-contact .l-article__body .wpcf7 .form-input textarea::placeholder {
  color: #aaa;
}
.s-privacy .l-article__body .wpcf7 .form-input textarea,
.s-contact .l-article__body .wpcf7 .form-input textarea {
  height: 180px;
}
.s-privacy .l-article__body .wpcf7 .inquiry-items,
.s-contact .l-article__body .wpcf7 .inquiry-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.s-privacy .l-article__body .wpcf7 .inquiry-items .wpcf7-form-control-wrap,
.s-contact .l-article__body .wpcf7 .inquiry-items .wpcf7-form-control-wrap {
  width: 100%;
}
.s-privacy .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item,
.s-contact .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item {
  display: inline-flex;
  align-items: center;
  margin: 0 2rem 0 0;
}
.s-privacy .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item input[type=checkbox],
.s-contact .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item input[type=checkbox] {
  margin-right: 0.5rem;
}
.s-privacy .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item .wpcf7-list-item-label,
.s-contact .l-article__body .wpcf7 .inquiry-items .wpcf7-list-item .wpcf7-list-item-label {
  font-size: 14px;
  font-size: 0.875rem;
}
.s-privacy .l-article__body .wpcf7 .postal-code-wrapper,
.s-contact .l-article__body .wpcf7 .postal-code-wrapper {
  display: flex;
  align-items: center;
}
.s-privacy .l-article__body .wpcf7 .postal-code-wrapper .postal-mark,
.s-contact .l-article__body .wpcf7 .postal-code-wrapper .postal-mark {
  margin-right: 0.5rem;
  font-size: 14px;
  font-size: 0.875rem;
}
.s-privacy .l-article__body .wpcf7 .postal-code-wrapper input[type=text],
.s-contact .l-article__body .wpcf7 .postal-code-wrapper input[type=text] {
  width: 150px;
}
.s-privacy .l-article__body .wpcf7 .file-description,
.s-contact .l-article__body .wpcf7 .file-description {
  font-size: 12px;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: #777;
}
.s-privacy .l-article__body .wpcf7 .privacy-policy-check,
.s-contact .l-article__body .wpcf7 .privacy-policy-check {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
@media screen and (max-width: 768px) {
  .s-privacy .l-article__body .wpcf7 .privacy-policy-check,
  .s-contact .l-article__body .wpcf7 .privacy-policy-check {
    justify-content: flex-start;
  }
}
.s-privacy .l-article__body .wpcf7 .privacy-policy-check .wpcf7-list-item,
.s-contact .l-article__body .wpcf7 .privacy-policy-check .wpcf7-list-item {
  margin: 0;
}
.s-privacy .l-article__body .wpcf7 .privacy-policy-check label,
.s-contact .l-article__body .wpcf7 .privacy-policy-check label {
  font-size: 14px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.s-privacy .l-article__body .wpcf7 .privacy-policy-check label input[type=checkbox],
.s-contact .l-article__body .wpcf7 .privacy-policy-check label input[type=checkbox] {
  margin-right: 0.5rem;
}
.s-privacy .l-article__body .wpcf7 .submit-button-container,
.s-contact .l-article__body .wpcf7 .submit-button-container {
  margin-top: 2rem;
  text-align: center;
}
.s-privacy .l-article__body .wpcf7 .submit-button-container input[type=submit],
.s-contact .l-article__body .wpcf7 .submit-button-container input[type=submit] {
  font-size: 16px;
  font-size: 1rem;
  padding: 1rem 3rem;
  color: white;
  cursor: pointer;
  background-color: #7c7c7c;
  border: none;
  border-radius: 4px;
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.s-privacy .l-article__body .wpcf7 .submit-button-container input[type=submit]:hover,
.s-contact .l-article__body .wpcf7 .submit-button-container input[type=submit]:hover {
  background-color: rgb(98.5, 98.5, 98.5);
}
.s-privacy .l-article__body .wpcf7 .wpcf7-not-valid-tip,
.s-contact .l-article__body .wpcf7 .wpcf7-not-valid-tip {
  font-size: 12px;
  font-size: 0.75rem;
  margin-top: 0.3rem;
  color: #e74c3c;
}
.s-privacy .l-article__body .wpcf7 .wpcf7-response-output,
.s-contact .l-article__body .wpcf7 .wpcf7-response-output {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0.75rem;
  margin: 2rem 0 0;
  text-align: center;
  border: 1px solid #ddd;
}
.s-privacy .l-article__body .wpcf7 .wpcf7-response-output.wpcf7-validation-errors,
.s-contact .l-article__body .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
  color: #e74c3c;
  background-color: #fef4f4;
  border-color: #e74c3c;
}
.s-privacy .l-article__body .wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ok,
.s-contact .l-article__body .wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ok {
  color: #2ecc71;
  background-color: #e8f8f5;
  border-color: #2ecc71;
}

.submit-button-container .wpcf7-form-control-wrap {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.s-home {
  /* New Solutions Section */
  /* Strong Point Section */
}
.s-home__video {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100vh;
  padding-top: 70px;
}
@media screen and (min-width: 960px) {
  .s-home__video {
    top: 0;
    padding: 0 0 0 220px;
  }
}
.s-home__video-pc {
  display: none;
  width: 100%;
  object-fit: cover;
}
@media screen and (min-width: 456px) {
  .s-home__video-pc {
    display: block;
  }
}
.s-home__video-mb {
  display: none;
}
@media screen and (max-width: 455px) {
  .s-home__video-mb {
    object-fit: cover;
    display: block;
    width: 100vw;
    height: 100vh;
  }
}
.s-home__cover {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 56.25%;
}
@media screen and (max-width: 455px) {
  .s-home__cover {
    padding-top: 177.7777%;
  }
}
.s-home__cover-inner {
  position: absolute;
  top: 0;
  display: table-cell;
  width: 100%;
  height: 100%;
  margin: 0;
}
.s-home__cover-text {
  position: absolute;
  top: calc(50% - 108px);
  width: 100%;
  text-align: center;
}
.s-home__cover-scroll {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.s-home__cover-scroll p {
  position: relative;
  width: 100px;
  padding: 0 0 370px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .s-home__cover-scroll p {
    width: 60px;
    padding: 0 0 200px;
  }
}
.s-home__cover-scroll p:before {
  position: absolute;
  bottom: 0;
  /* left: 50%; */
  left: 61px;
  display: block;
  width: 2px;
  height: 230px;
  margin-left: -2px;
  content: "";
  background: #fff;
  animation: line-scroll 2s cubic-bezier(0.5, 0.06, 0.3, 0.9) infinite;
  animation-fill-mode: both;
}
@media screen and (max-width: 768px) {
  .s-home__cover-scroll p:before {
    left: 42px;
    height: 85px;
  }
}
.s-home__cover-scroll span {
  font-size: 18px;
  font-size: 1.125rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.5em;
  transform: translate(0, 80px) rotate(90deg);
}
@media screen and (max-width: 768px) {
  .s-home__cover-scroll span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.s-home__information {
  background-color: #f0f0f0;
}
.s-home__information-content {
  display: flex;
  width: 94%;
  max-width: 1440px;
  padding: 50px 0;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .s-home__information-content {
    flex-direction: column;
  }
}
.s-home__information-headings {
  font-size: 15px;
  font-size: 0.9375rem;
  width: 20%;
  max-width: 300px;
  font-weight: 700;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .s-home__information-headings {
    width: 100%;
  }
}
.s-home__information-headings:before {
  font-size: 26px;
  font-size: 1.625rem;
  display: block;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  color: #163160;
  letter-spacing: 0.25em;
  content: attr(data-text);
}
.s-home__about {
  position: relative;
  display: flex;
}
@media screen and (max-width: 959px) {
  .s-home__about {
    flex-direction: column;
  }
}
.s-home__about:before {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 0;
  display: inline-block;
  width: clamp(350px, 32%, 600px);
  height: 40px;
  content: "";
  background-color: #163160;
}
.s-home__about-content {
  width: 50%;
  padding: 100px 1rem 80px;
}
@media screen and (max-width: 959px) {
  .s-home__about-content {
    width: 100%;
  }
}
.s-home__about-headings {
  font-size: 26px;
  font-size: 1.625rem;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.25em;
}
.s-home__about-headings span {
  position: relative;
  z-index: 10;
}
.s-home__about-copy {
  font-size: 19px;
  font-size: 1.1875rem;
  margin-top: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.25em;
}
.s-home__about-text {
  margin-top: 2rem;
}
.s-home__about-button {
  margin-top: 3rem;
}
.s-home__about-img {
  width: 50%;
  background-size: cover;
}
@media screen and (max-width: 959px) {
  .s-home__about-img {
    width: 100%;
    height: 300px;
  }
}
.s-home__solution {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-size: cover;
}
.s-home__solution:before {
  position: absolute;
  top: 100px;
  right: 0;
  z-index: 0;
  display: inline-block;
  width: clamp(350px, 32%, 600px);
  height: 40px;
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
}
.s-home__solution-content {
  width: 50%;
  padding: 100px 1rem 200px 0;
  color: #fff;
}
@media screen and (max-width: 1250px) {
  .s-home__solution-content {
    width: 85%;
  }
}
@media screen and (max-width: 768px) {
  .s-home__solution-content {
    width: 100%;
    padding: 100px 1rem 50px;
  }
}
.s-home__solution-headings {
  font-size: 26px;
  font-size: 1.625rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  color: #163160;
  letter-spacing: 0.25em;
}
.s-home__solution-body {
  padding: 1.5rem;
  margin-top: 1.5rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
}
.s-home__solution-copy {
  font-size: 19px;
  font-size: 1.1875rem;
  font-weight: 700;
  text-align: right;
  letter-spacing: 0.25em;
}
.s-home__solution-text {
  margin-top: 1rem;
}
.s-home__solution-button {
  margin-top: 3rem;
  text-align: right;
}
.s-home__solution-button a {
  background-color: transparent;
  border: 2px solid #fff;
}
.s-home__solutions {
  width: 94%;
  max-width: 1440px;
  padding: 60px 0;
  margin: auto;
}
.s-home__solutions-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 15%;
}
@media screen and (max-width: 768px) {
  .s-home__solutions-grid {
    flex-direction: column;
    margin-right: 0;
  }
}
.s-home__solutions-card {
  width: 31%;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .s-home__solutions-card {
    width: 100%;
  }
}
.s-home__solutions-image {
  position: relative;
  margin-bottom: calc(30px + 0.5rem);
}
.s-home__solutions-image img {
  display: block;
  width: 100%;
  height: auto;
}
.s-home__solutions-title {
  position: absolute;
  bottom: -30px;
  left: 0;
  margin: 0;
}
.s-home__strong-point {
  position: relative;
  width: 94%;
  max-width: 1440px;
  padding: 0 0 80px;
  margin: auto;
}
.s-home__strong-point:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  display: inline-block;
  width: clamp(350px, 32%, 600px);
  height: 40px;
  content: "";
  background-color: #163160;
}
.s-home__strong-point-header {
  font-size: 26px;
  font-size: 1.625rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  margin-right: 1rem;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.25em;
}
.s-home__strong-point-benefits {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
@media screen and (max-width: 959px) {
  .s-home__strong-point-benefits {
    flex-direction: column;
  }
}
.s-home__strong-point-item {
  width: calc(50% - 0.5rem);
}
@media screen and (max-width: 959px) {
  .s-home__strong-point-item {
    width: 100%;
    margin-bottom: 40px;
  }
}
.s-home__strong-point-image img {
  width: 100%;
  height: auto;
}
.s-home__strong-point-title {
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 1rem;
  font-weight: bold;
  color: #163160;
  text-align: center;
}
.s-home__strong-point-description {
  margin-top: 0.5rem;
}
.s-home__access {
  position: relative;
}
.s-home__access:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 93px;
  content: "";
  background-color: #d4d4d4;
}
.s-home__access-headings {
  font-size: 26px;
  font-size: 1.625rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 94%;
  max-width: 1440px;
  height: 93px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  letter-spacing: 0.25em;
}
.s-home__access-content {
  display: flex;
  align-items: flex-end;
  padding: 1.5rem 1rem 0;
}
@media screen and (max-width: 959px) {
  .s-home__access-content {
    flex-direction: column;
    align-items: unset;
    padding: 0 !important;
  }
}
.s-home__access-map {
  width: 760px;
  height: 380px;
}
@media screen and (max-width: 959px) {
  .s-home__access-map {
    width: 100%;
  }
}
@media screen and (min-width: 960px) {
  .s-home__access-address {
    padding-left: 2rem !important;
  }
}
@media screen and (max-width: 959px) {
  .s-home__access-address {
    margin-top: 1rem;
  }
}
.s-home__access-name {
  font-size: 19px;
  font-size: 1.1875rem;
}
.s-home__access address {
  font-weight: 700;
}

/*! purgecss end ignore */
.s-news__lists {
  width: calc(100% - 220px - 4rem);
  text-align: center;
}
@media screen and (max-width: 959px) {
  .s-news__lists {
    width: calc(100% - 150px - 2rem);
  }
}
@media screen and (max-width: 768px) {
  .s-news__lists {
    width: 100%;
  }
}
.s-news__aside {
  font-size: 14px;
  font-size: 0.875rem;
  width: 220px;
  margin-top: 2.5rem;
}
@media screen and (max-width: 959px) {
  .s-news__aside {
    width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .s-news__aside {
    width: 100%;
  }
}
.s-news__article {
  padding: 4rem 0 0;
  margin: 4rem 0 0;
  text-align: left;
  border-top: 1px solid #505050;
}
@media screen and (max-width: 768px) {
  .s-news__article {
    padding: 70px 0;
    margin: 0;
  }
}
.s-news__article:first-of-type {
  border: none;
}
h1 + .s-news__article:first-of-type {
  margin-top: 2.5rem;
}
.s-news__time {
  font-size: 15px;
  font-size: 0.9375rem;
  padding-top: 70px;
  margin-top: -70px;
  font-weight: 700;
}
.s-news__title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: 700;
  color: #163160;
}
.s-news__content {
  margin-top: 1rem;
}
.s-news__content p {
  margin-top: 1rem;
}
.s-news__subheadings {
  margin-top: 2.5rem;
  color: #163160;
}
.s-news__aside-list {
  margin-top: 0.5rem;
  list-style: none;
  border-top: 1px solid #505050;
}
.s-news__aside-list li {
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  .s-news__aside-list li {
    display: inline-block;
    margin-right: 1rem;
  }
}
.s-news__lists-headings {
  font-size: 20px;
  font-size: 1.25rem;
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: auto;
  margin-top: 5rem;
  color: #163160;
}
.s-news__lists-headings:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #163160;
}

/*! purgecss start ignore */
.s-privacy .l-article__body p {
  text-align: left;
}
.s-privacy .l-article__body p:first-child {
  padding-top: 5rem;
  margin-top: 0;
}
.s-privacy .l-article__body ul {
  margin-top: 1rem;
}
.s-privacy .l-article__body ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 1rem;
  list-style: none;
}
.s-privacy .l-article__body ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #e3e3e3;
  content: "●";
}
.s-privacy .l-article__body h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  padding: 0.25rem 0.75rem;
  margin-top: 5rem;
  background-color: #f0f0f0;
  border: 2px solid #e3e3e3;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.l-article {
  background-color: #fff;
}
.l-article__body {
  width: 94%;
  max-width: 1440px;
  margin: auto;
}
.s-news .l-article__body {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .s-news .l-article__body {
    flex-direction: column;
  }
}
.s-solution .l-article__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 2rem;
}
.s-solution .l-article__body::after {
  flex: auto;
  content: "";
}

.s-solution__card {
  max-width: none;
}
@media (min-width: 1440px) {
  .s-solution__card {
    width: calc(25% - 1.5rem);
    margin-top: 3rem;
    margin-right: 2rem;
  }
  .s-solution__card:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .s-solution__card {
    width: calc(33.333% - 1.33333rem);
    margin-top: 3rem;
    margin-right: 2rem;
  }
  .s-solution__card:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1250px) {
  .s-solution__card {
    width: calc(33.333% - 1.33333rem);
    margin-top: 3rem;
    margin-right: 2rem;
  }
  .s-solution__card:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 455px) {
  .s-solution__card {
    width: 100%;
    max-width: 100%;
    margin: 3rem 0 0 0;
  }
}
.s-solution__card:after {
  content: unset !important;
}
.s-solution__card figure {
  position: relative;
  width: 100%;
  padding-top: 61.8296%;
}
.s-solution__card figure:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  content: "";
  background-color: rgba(255, 255, 255, 0.4);
  transition: 0.4s cubic-bezier(0.7, 0.1, 0.1, 0.9);
}
.s-solution__card figure img {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 455px) {
  .s-solution__card figure img {
    width: 100%;
  }
}
.s-solution__card:hover figure:after {
  width: 100%;
}
.s-solution__card-caption dt {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0 10px;
  margin: 0.5rem 0;
}
.s-solution__card-caption dd {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0.5rem 10px 0;
  border-top: 1px solid #163160;
}

.s-solution-detail .l-article__body {
  padding: 5rem 0 0;
}

/*! purgecss end ignore */
.u-align-left {
  display: block !important;
  margin-right: auto !important;
  margin-left: 0 !important;
}

.u-align-center {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.u-align-right {
  display: block !important;
  margin-right: 0 !important;
  margin-left: auto !important;
}

.u-clear {
  clear: both;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-font10 {
  font-size: 10px;
  font-size: 0.625rem;
}

.u-font11 {
  font-size: 11px;
  font-size: 0.6875rem;
}

.u-font12 {
  font-size: 12px;
  font-size: 0.75rem;
}

.u-font13 {
  font-size: 13px;
  font-size: 0.8125rem;
}

.u-font14 {
  font-size: 14px;
  font-size: 0.875rem;
}

.u-font15 {
  font-size: 15px;
  font-size: 0.9375rem;
}

.u-font16 {
  font-size: 16px;
  font-size: 1rem;
}

.u-font17 {
  font-size: 17px;
  font-size: 1.0625rem;
}

.u-font18 {
  font-size: 18px;
  font-size: 1.125rem;
}

.u-font19 {
  font-size: 19px;
  font-size: 1.1875rem;
}

.u-font20 {
  font-size: 20px;
  font-size: 1.25rem;
}

/*! purgecss start ignore */
.u-mt0 {
  margin-top: 0 !important;
}

.u-mt05 {
  margin-top: 0.5rem !important;
}

.u-mt1 {
  margin-top: 1rem !important;
}

.u-mt2 {
  margin-top: 2rem !important;
}

.u-mt3 {
  margin-top: 3rem !important;
}

.u-mt4 {
  margin-top: 4rem !important;
}

.u-mt5 {
  margin-top: 5rem !important;
}

.u-mr0 {
  margin-right: 0 !important;
}

.u-mr05 {
  margin-right: 0.5rem !important;
}

.u-mr1 {
  margin-right: 1rem !important;
}

.u-mr2 {
  margin-right: 2rem !important;
}

.u-mr3 {
  margin-right: 3rem !important;
}

.u-mb0 {
  margin-bottom: 0 !important;
}

.u-mb05 {
  margin-bottom: 0.5rem !important;
}

.u-mb1 {
  margin-bottom: 1rem !important;
}

.u-mb2 {
  margin-bottom: 2rem !important;
}

.u-mb3 {
  margin-bottom: 3rem !important;
}

.u-mb4 {
  margin-bottom: 4rem !important;
}

.u-mb5 {
  margin-bottom: 5rem !important;
}

.u-ml0 {
  margin-left: 0 !important;
}

.u-ml05 {
  margin-left: 0.5rem !important;
}

.u-ml1 {
  margin-left: 1rem !important;
}

.u-ml2 {
  margin-left: 2rem !important;
}

.u-ml3 {
  margin-left: 3rem !important;
}

/*! purgecss end ignore */
.u-pt0 {
  padding-top: 0 !important;
}

.u-pt05 {
  padding-top: 0.5rem !important;
}

.u-pt1 {
  padding-top: 1rem !important;
}

.u-pt2 {
  padding-top: 2rem !important;
}

.u-pt3 {
  padding-top: 3rem !important;
}

.u-pt4 {
  padding-top: 4rem !important;
}

.u-pt5 {
  padding-top: 5rem !important;
}

.u-pr0 {
  padding-right: 0 !important;
}

.u-pr05 {
  padding-right: 0.5rem !important;
}

.u-pr1 {
  padding-right: 1rem !important;
}

.u-pr2 {
  padding-right: 2rem !important;
}

.u-pr3 {
  padding-right: 3rem !important;
}

.u-pb0 {
  padding-bottom: 0 !important;
}

.u-pb05 {
  padding-bottom: 0.5rem !important;
}

.u-pb1 {
  padding-bottom: 1rem !important;
}

.u-pb2 {
  padding-bottom: 2rem !important;
}

.u-pb3 {
  padding-bottom: 3rem !important;
}

.u-pb4 {
  padding-bottom: 4rem !important;
}

.u-pb5 {
  padding-bottom: 5rem !important;
}

.u-pl0 {
  padding-left: 0 !important;
}

.u-pl05 {
  padding-left: 0.5rem !important;
}

.u-pl1 {
  padding-left: 1rem !important;
}

.u-pl2 {
  padding-left: 2rem !important;
}

.u-pl3 {
  padding-left: 3rem !important;
}

.u-percent5 {
  width: 5% !important;
}

.u-percent10 {
  width: 10% !important;
}

.u-percent15 {
  width: 15% !important;
}

.u-percent20 {
  width: 20% !important;
}

.u-percent25 {
  width: 25% !important;
}

.u-percent30 {
  width: 30% !important;
}

.u-percent35 {
  width: 35% !important;
}

.u-percent40 {
  width: 40% !important;
}

.u-percent45 {
  width: 45% !important;
}

.u-percent50 {
  width: 50% !important;
}

.u-percent55 {
  width: 55% !important;
}

.u-percent60 {
  width: 60% !important;
}

.u-percent65 {
  width: 65% !important;
}

.u-percent70 {
  width: 70% !important;
}

.u-percent75 {
  width: 75% !important;
}

.u-percent80 {
  width: 80% !important;
}

.u-percent85 {
  width: 85% !important;
}

.u-percent90 {
  width: 90% !important;
}

.u-percent95 {
  width: 95% !important;
}

.u-percent100 {
  width: 100% !important;
}

/* 先頭の1文字目を1文字分字下げします。 */
.u-text-indent {
  text-indent: 1em !important;
}

/* 2行目以降の1文字目を1文字分字下げします。 */
.u-text-note {
  margin-left: 1em !important;
  text-indent: -1em !important;
}

/* テキストを改行させません。 */
.u-text-nowrap {
  white-space: nowrap !important;
}

/* テキストを改行させます。 */
.u-text-breakword {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

/* テキストを両端揃えさせます。 */
.u-text-justify {
  text-align: justify !important;
  text-justify: inter-ideograph !important;
}

/* 記述した通りに表示します。 */
.u-text-none {
  text-transform: none !important;
}

/* すべての英単語を小文字にします。 */
.u-text-lowercase {
  text-transform: lowercase !important;
}

/* すべての英単語を大文字にします。 */
.u-text-uppercase {
  text-transform: uppercase !important;
}

/* 英単語の先頭を大文字にします。 */
.u-text-capitalize {
  text-transform: capitalize !important;
}

/* スモールキャップ（小文字と同じ高さで作られた大文字）にします。 */
.u-text-smallcaps {
  font-variant: small-caps !important;
}

/* 縦書きにします。 */
.u-text-vertical {
  text-orientation: mixed !important;
  writing-mode: vertical-rl !important;
}

/* 英文も縦書きにする場合はModifierを指定します。 */
.u-text-vertical--upright {
  text-orientation: upright !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  /* purgecss ignore current */
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-top {
  vertical-align: top !important;
}

.u-text-middle {
  vertical-align: middle !important;
}

.u-text-bottom {
  vertical-align: bottom !important;
}