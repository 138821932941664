/*! purgecss start ignore */
.s-home {
  &__video {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100vh;
    padding-top: 70px;

    @include mq-up(md-lg) {
      top: 0;
      padding: 0 0 0 220px;
    }

    &-pc {
      display: none;
      width: 100%;
      object-fit: cover;

      @include mq-up(sm) {
        display: block;
      }
    }

    &-mb {
      display: none;

      @include mq-down(sm) {
        object-fit: cover;
        display: block;
        width: 100vw;
        height: 100vh;
      }
    }
  }

  &__cover {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 56.25%;

    @include mq-down(sm) {
      padding-top: 177.7777%;
    }

    &-inner {
      position: absolute;
      top: 0;
      display: table-cell;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &-text {
      position: absolute;
      top: calc(50% - 108px);
      width: 100%;
      text-align: center;
    }

    &-scroll {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 5;

      p {
        position: relative;
        width: 100px;
        padding: 0 0 370px;
        overflow: hidden;

        @include mq-down(md) {
          width: 60px;
          padding: 0 0 200px;
        }

        &:before {
          position: absolute;
          bottom: 0;
          /* left: 50%; */
          left: 61px;
          display: block;
          width: 2px;
          height: 230px;
          margin-left: -2px;
          content: '';
          background: #fff;
          animation: line-scroll 2s cubic-bezier(0.5, 0.06, 0.3, 0.9) infinite;
          animation-fill-mode: both;

          @include mq-down(md) {
            left: 42px;
            height: 85px;
          }
        }
      }

      span {
        @include font-size(18);
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.5em;
        transform: translate(0, 80px) rotate(90deg);

        @include mq-down(md) {
          @include font-size(12);
        }
      }
    }
  }

  &__information {
    background-color: #f0f0f0;

    &-content {
      display: flex;
      width: 94%;
      max-width: 1440px;
      padding: 50px 0;
      margin: auto;

      @include mq-down(md) {
        flex-direction: column;
      }
    }

    &-headings {
      @include font-size(15);
      width: 20%;
      max-width: 300px;
      font-weight: 700;
      line-height: 1;

      @include mq-down(md) {
        width: 100%;
      }

      &:before {
        @include font-size(26);
        display: block;
        margin-bottom: 0.5rem;
        font-family: 'Poppins', sans-serif;
        color: $color_primary;
        letter-spacing: 0.25em;
        content: attr(data-text);
      }
    }
  }

  &__about {
    position: relative;
    display: flex;

    @include mq-down(md-lg) {
      flex-direction: column;
    }

    &:before {
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 0;
      display: inline-block;
      width: clamp(350px, 32%, 600px);
      height: 40px;
      content: '';
      background-color: $color_primary;
    }

    &-content {
      width: 50%;
      padding: 100px 1rem 80px;

      @include mq-down(md-lg) {
        width: 100%;
      }
    }

    &-headings {
      @include font-size(26);
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      font-family: 'Poppins', sans-serif;
      line-height: 1;
      color: #fff;
      letter-spacing: 0.25em;

      span {
        position: relative;
        z-index: 10;
      }
    }

    &-copy {
      @include font-size(19);
      margin-top: 1.5rem;
      font-weight: 700;
      letter-spacing: 0.25em;
    }

    &-text {
      margin-top: 2rem;
    }

    &-button {
      margin-top: 3rem;
    }

    &-img {
      width: 50%;
      background-size: cover;

      @include mq-down(md-lg) {
        width: 100%;
        height: 300px;
      }
    }
  }

  &__solution {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-size: cover;

    &:before {
      position: absolute;
      top: 100px;
      right: 0;
      z-index: 0;
      display: inline-block;
      width: clamp(350px, 32%, 600px);
      height: 40px;
      content: '';
      background-color: rgba(#fff, 0.7);
    }

    &-content {
      width: 50%;
      padding: 100px 1rem 200px 0;
      color: #fff;

      @include mq-down(lg) {
        width: 85%;
      }

      @include mq-down(md) {
        width: 100%;
        padding: 100px 1rem 50px;
      }
    }

    &-headings {
      @include font-size(26);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      font-family: 'Poppins', sans-serif;
      line-height: 1;
      color: $color_primary;
      letter-spacing: 0.25em;
    }

    &-body {
      padding: 1.5rem;
      margin-top: 1.5rem;
      font-weight: bold;
      background-color: rgba(#000, 0.5);
    }

    &-copy {
      @include font-size(19);
      font-weight: 700;
      text-align: right;
      letter-spacing: 0.25em;
    }

    &-text {
      margin-top: 1rem;
    }

    &-button {
      margin-top: 3rem;
      text-align: right;

      a {
        background-color: transparent;
        border: 2px solid #fff;
      }
    }
  }

  /* New Solutions Section */
  &__solutions {
    width: 94%;
    max-width: 1440px;
    padding: 60px 0;
    margin: auto;

    &-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 15%;

      @include mq-down(md) {
        flex-direction: column;
        margin-right: 0;
      }
    }

    &-card {
      width: 31%;
      margin-bottom: 30px;

      @include mq-down(md) {
        width: 100%;
      }
    }

    &-image {
      position: relative;
      margin-bottom: calc(30px + 0.5rem);

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-title {
      @extend .c-button__primary;
      position: absolute;
      bottom: -30px;
      left: 0;
      margin: 0;
    }
  }

  /* Strong Point Section */
  &__strong-point {
    position: relative;
    width: 94%;
    max-width: 1440px;
    padding: 0 0 80px;
    margin: auto;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      display: inline-block;
      width: clamp(350px, 32%, 600px);
      height: 40px;
      content: '';
      background-color: $color_primary;
    }

    &-header {
      @include font-size(26);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      margin-right: 1rem;
      font-family: 'Poppins', sans-serif;
      line-height: 1;
      color: #fff;
      letter-spacing: 0.25em;
    }

    &-benefits {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;

      @include mq-down(md-lg) {
        flex-direction: column;
      }
    }

    &-item {
      width: calc(50% - 0.5rem);

      @include mq-down(md-lg) {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    &-image {
      img {
        width: 100%;
        height: auto;
      }
    }

    &-title {
      @include font-size(18);
      margin-top: 1rem;
      font-weight: bold;
      color: $color_primary;
      text-align: center;
    }

    &-description {
      margin-top: 0.5rem;
    }
  }

  &__access {
    position: relative;

    &:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 93px;
      content: '';
      background-color: #d4d4d4;
    }

    &-headings {
      @include font-size(26);
      position: relative;
      display: flex;
      align-items: center;
      width: 94%;
      max-width: 1440px;
      height: 93px;
      margin: auto;
      font-family: 'Poppins', sans-serif;
      line-height: 1;
      letter-spacing: 0.25em;
    }

    &-content {
      display: flex;
      align-items: flex-end;
      padding: 1.5rem 1rem 0;

      @include mq-down(md-lg) {
        flex-direction: column;
        align-items: unset;
        padding: 0 !important;
      }
    }

    &-map {
      width: 760px;
      height: 380px;

      @include mq-down(md-lg) {
        width: 100%;
      }
    }

    &-address {
      @include mq-up(md-lg) {
        padding-left: 2rem !important;
      }

      @include mq-down(md-lg) {
        margin-top: 1rem;
      }
    }

    &-name {
      @include font-size(19);
    }

    address {
      font-weight: 700;
    }
  }
}
/*! purgecss end ignore */
