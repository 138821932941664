/*! purgecss start ignore */
.s-news__content,
.s-solution-detail .l-article__body {
  h2 {
    @include font-size(28);
    padding: 0.25rem 0 0 0.5rem;
    margin-top: 5rem;
    color: $color_primary;
    border-left: 7px solid $color_primary;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    @include font-size(21);
    padding: 0.25rem 0.75rem;
    margin-top: 5rem;
    background-color: #f0f0f0;
    border: 2px solid #e3e3e3;
  }

  h4 {
    @include font-size(20);
    margin-top: 4rem;
    color: $color_primary;
  }

  h3 + h4 {
    margin-top: 1.5rem;
  }

  h2 + .wp-block-image,
  h3 + .wp-block-image,
  h4 + .wp-block-image,
  h2 + .wp-block-media-text,
  h3 + .wp-block-media-text,
  h4 + .wp-block-media-text {
    margin: 1.5rem 0 0;
  }

  ul {
    padding: 0 0.5rem;
    margin-top: 2rem;
    border-left: 7px solid #e3e3e3;

    li {
      @include font-size(15);
      position: relative;
      padding-left: 1rem;
      list-style: none;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        color: #e3e3e3;
        content: '●';
      }
    }
  }

  p {
    margin-top: 1rem;
  }
}

.wp-block-columns {
  margin: 2.5rem 0 0;
}

.wp-block-image {
  margin: 2.5rem 0 0;

  &:first-child {
    margin: 0;
  }

  figcaption {
    @include font-size(15);
    margin: 0.5em 0 0;

    strong {
      @include font-size(18);
      color: $color_primary;
    }
  }
}

.wp-block-video,
.wp-block-media-text {
  margin: 2.5rem 0 0;
}

.wp-block-table {
  margin: 1.5rem 0 0;

  table {
    border-spacing: 0;
    border-collapse: collapse;
    /* border: 1px solid #c8c8c8; */

    @include mq-down(md) {
      border-top: 1px solid #c8c8c8;
    }

    td {
      @include font-size(15);
      padding: 0.25rem 0.5rem;
      border: 1px solid #c8c8c8;

      @include mq-down(md) {
        display: block;
        border-top: none;
      }
    }

    tr {
      td:nth-of-type(1) {
        background-color: #ebebeb;
        border-right: none;

        @include mq-down(md) {
          border: 1px solid #c8c8c8;
          border-top: none;
        }
      }

      td:nth-of-type(2) {
        border-left: none;

        @include mq-down(md) {
          border: 1px solid #c8c8c8;
          border-top: none;
        }
      }
    }
  }
}

.wp-block-button__link {
  @extend %button;
  background-color: #aaa;
}
/*! purgecss end ignore */
