/*! purgecss start ignore */
.s-privacy .l-article__body,
.s-contact .l-article__body {
  padding-top: 1rem;

  h2 {
    @include font-size(21);
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: auto;
    margin-top: 5rem;
    color: $color_primary;
    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      content: '';
      background-color: $color_primary;
    }
  }

  p {
    margin-top: 1.5rem;
    text-align: center;

    @include mq-down(md-lg) {
      text-align: left;
    }
  }
  strong {
    color: $color_primary;
  }
  .wp-block-group + .wp-block-group {
    p {
      margin: 1rem auto 0;
      text-align: center;
    }
    strong {
      @include font-size(24);
      @include mq-down(sm) {
        @include font-size(20);
      }
      a {
        color: $color_primary;
      }
    }
  }

  // Contact Form 7のスタイル
  .wpcf7 {
    max-width: 920px;
    margin: 2rem auto 0;

    // フォーム説明文
    p {
      @include font-size(14);
      margin-top: 1.5rem;
      line-height: 1.8;
      text-align: center;

      @include mq-down(md-lg) {
        text-align: left;
      }
    }

    // フォームテーブル
    form {
      width: 100%;
      border-top: 1px solid #ddd;
    }

    // フォーム行
    .form-row {
      display: flex;
      border-bottom: 1px solid #ddd;

      @include mq-down(md) {
        flex-direction: column;
      }
    }

    // ラベル部分 - 横並びに調整
    .form-label {
      display: flex;
      align-items: center;
      width: 30%;
      padding: 1.5rem 1rem;
      background-color: #f5f5f5;

      @include mq-down(md) {
        width: 100%;
        padding: 1rem;
      }

      label {
        @include font-size(14);
        margin: 0;
        font-weight: normal;
      }

      .required {
        @include font-size(12);
        display: inline-block;
        padding: 2px 5px;
        margin-left: 0.5rem;
        color: #fff;
        background-color: #e74c3c;
        border-radius: 2px;
      }
    }

    // 入力部分
    .form-input {
      width: 70%;
      padding: 1.5rem 1rem;

      @include mq-down(md) {
        width: 100%;
        padding: 1rem;
      }

      input[type='text'],
      input[type='email'],
      input[type='tel'],
      select,
      textarea {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 3px;
        @include font-size(14);

        &::placeholder {
          color: #aaa;
        }
      }

      textarea {
        height: 180px;
      }
    }

    // お問い合わせ項目（チェックボックス）を横並びに
    .inquiry-items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .wpcf7-form-control-wrap {
        width: 100%;
      }

      .wpcf7-list-item {
        display: inline-flex;
        align-items: center;
        margin: 0 2rem 0 0;

        input[type='checkbox'] {
          margin-right: 0.5rem;
        }

        .wpcf7-list-item-label {
          @include font-size(14);
        }
      }
    }

    // 郵便番号を横並びに
    .postal-code-wrapper {
      display: flex;
      align-items: center;

      .postal-mark {
        margin-right: 0.5rem;
        @include font-size(14);
      }

      input[type='text'] {
        width: 150px;
      }
    }

    // ファイル添付
    .file-description {
      @include font-size(12);
      margin-top: 0.5rem;
      color: #777;
    }

    // 個人情報同意チェックボックス
    .privacy-policy-check {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      @include mq-down(md) {
        justify-content: flex-start;
      }

      .wpcf7-list-item {
        margin: 0;
      }

      label {
        @include font-size(14);
        display: flex;
        align-items: center;

        input[type='checkbox'] {
          margin-right: 0.5rem;
        }
      }
    }

    // 送信ボタン
    .submit-button-container {
      margin-top: 2rem;
      text-align: center;

      input[type='submit'] {
        @include font-size(16);
        padding: 1rem 3rem;
        color: white;
        cursor: pointer;
        background-color: #7c7c7c;
        border: none;
        border-radius: 4px;
        transition: $duration;

        &:hover {
          background-color: darken(#7c7c7c, 10%);
        }
      }
    }

    // エラーメッセージ
    .wpcf7-not-valid-tip {
      @include font-size(12);
      margin-top: 0.3rem;
      color: #e74c3c;
    }

    .wpcf7-response-output {
      @include font-size(14);
      padding: 0.75rem;
      margin: 2rem 0 0;
      text-align: center;
      border: 1px solid #ddd;

      &.wpcf7-validation-errors {
        color: #e74c3c;
        background-color: #fef4f4;
        border-color: #e74c3c;
      }

      &.wpcf7-mail-sent-ok {
        color: #2ecc71;
        background-color: #e8f8f5;
        border-color: #2ecc71;
      }
    }
  }
}

.submit-button-container .wpcf7-form-control-wrap {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
/*! purgecss end ignore */
