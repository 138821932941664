/*! purgecss start ignore */
%button {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 250px;
  padding: 1rem 0.5rem;
  margin: auto;
  font-weight: 700;
  color: #fff;
  text-align: center;

  &:hover,
  &:visited {
    color: #fff;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: inline-block;
    width: 0;
    height: 100%;
    content: '';
    background-color: $color_primary;
    transition: $duration;
  }

  &:after {
    position: absolute;
    top: calc(50% - 5px);
    right: 13px;
    display: inline-block;
    width: 8px;
    height: 8px;
    content: '';
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(45deg);
  }
}

.c-button {
  &__primary {
    @extend %button;
    background-color: #aaa;

    /* &:hover { */
    /*   background-color: rgba(#aaa, 0.7); */
    /* } */

    @include mq-down(md) {
      & + .c-button__primary {
        margin-top: 1rem;
      }
    }
  }
}
/*! purgecss end ignore */
