.l-main .c-table-hours {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid $color_border;

  // @include mq-down(md) {
  //   margin-top: .5rem;
  // }

  tr:nth-of-type(2) {
    background-color: #f2f2f2;
  }

  th {
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background: #7b7b7b;
    border-left: 1px solid $color_border;
  }

  td {
    padding: 0.5rem 0;
    line-height: 1.3;
    text-align: center;
    border-top: 1px solid $color_border;
    border-left: 1px solid $color_border;

    div {
      @include font-size(12);
    }
  }

  .surgrey {
    @include font-size(13);

    div {
      display: block;
    }
  }

  &__head {
    width: 256px;
  }

  &__caption {
    margin-top: 0.5rem;
    // color: $color_text2;

    @include mq-up(md) {
      @include font-size(14);
    }

    @include mq-down(md) {
      // @include font-size-mb(12);
    }
  }

  &__open {
    @include font-size(12);
  }
}

.c-table-recruit {
  margin-top: 50px;

  caption {
    @include font-size(30);

    font-family: $font-family-serif;
    font-weight: 100;
  }

  th {
    width: 20%;
    padding: 0.75rem;
    text-align: right;
    border-top: 1px solid $color_border;
  }

  td {
    width: 80%;
    padding: 0.75rem;
    border-top: 1px solid $color_border;
  }
}
